import React, { useState } from "react";
import "./LogoSection.css";
import FestivalCountdown from "../FestivalCountdown";
import ApplicationsCountdown from "../ApplicationsCountdown";
import { getDate } from "../../helpers";

const LogoSection = () => {
  const applicationsStart = "20.10.2024";
  const applicationsEnd = "27.11.2024";
  const fest = new Date().getTime() >= getDate("14.12.2024").getTime();

  let applicationsTitle = "До начала приема заявок:";
  let applicationDate = applicationsStart;

  if (getDate(applicationsStart).getTime() - new Date().getTime() < 0) {
    applicationsTitle = "До окончания приема заявок:";
    applicationDate = applicationsEnd;
  }
  return (
    <>
      <div
        className="screen-section color-section container-v center desktop-section"
        style={{ position: "relative" }}
      >
        <img className="clouds" src="lines2.png" alt="NYAF" />
        {/* <div
        className="container-v center logo-container content"
        style={{ position: "relative" }}
      > */}
        <div className="container-v center logo-container">
          <div className="container upper-part">
            <div className="logo-image-container">
              <img
                src="cyberpunk_logo.png"
                alt=""
                className="logo-img logo-img1"
              />
              <img
                src="cyberpunk_logo2.png"
                alt=""
                className="logo-img logo-img2"
              />
              <img
                src="cyberpunk_logo3.png"
                alt=""
                className="logo-img logo-img3"
              />
              <img
                src="cyberpunk_logo4.png"
                alt=""
                className="logo-img logo-img4"
              />
            </div>

            {/* <div className="logo-image-container">
              <img
                style={{ maxWidth: "100%", alignSelf: "center" }}
                className="logo-image logo-img"
                src={"cyberpunk_logo.png"}
                alt="Cyberpunk"
              />
              <img
                style={{ maxWidth: "100%", alignSelf: "center" }}
                className="logo-image logo-gif"
                src={"cyberpunk_logo.gif"}
                alt="Cyberpunk"
              />
            </div> */}
            <div
              className="text black-text"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              14 ДЕКАБРЯ <br />
              Уфа \\ ГКДЦ «ХИМИК»
            </div>
          </div>
          <div className="container central-part center">
            <div style={{ position: "relative", height: "100%" }}>
              <img
                className="mascot-background"
                src="MascotBG.png"
                alt="mascot"
              />
              <ApplicationsCountdown
                targetDate={applicationDate}
                title={applicationsTitle.toUpperCase()}
              />
            </div>
            <img className="mascot" src="Mascot2.png" alt="mascot" />
          </div>
        </div>
        {/* 
          {!fest && (
            <button
              onClick={() => {
                window.location.pathname = "/tickets";
              }}
              className="text buy-ticket-button wrap"
            >
              КУПИТЬ БИЛЕТ
            </button>
          )} */}
        {/* {fest && (
          <>
            <button
              onClick={() => {
                window.location.pathname = "/schedule";
              }}
              className="text buy-ticket-button wrap"
              style={{
                marginTop: "55px",
                marginBottom: "5px",
                fontSize: "25px",
                padding: "20px 38px",
                borderRadius: "15px",
              }}
            >
              ПРОГРАММА ФЕСТИВАЛЯ
            </button>
            <div
              className="text black-text"
              style={{
                minWidth: "290px",
                marginTop: "11px",
                whiteSpace: "nowrap",
                fontSize: "20px",
              }}
            >
              Программа фестиваля, зрительское <br /> голосование, карта ДК и{" "}
              <br /> полезная информация
            </div>
          </>
        )} */}

        {/* {!fest && <FestivalCountdown targetDate={"14.12.2024"} />} */}

        {/* <div className="image-container-bg">
        <img className="image" src="Mascot2.png" alt="mascot" />
        <ApplicationsCountdown
          targetDate={applicationDate}
          title={applicationsTitle}
        />
      </div> */}
        {/* </div> */}
      </div>
      <div className="color-section screen-section mobile">
        <div className="mobile-section container-v center">
          <div className="container-v" style={{ padding: "0px 10px" }}>
            <img
              src="cyberpunk_logo.png"
              alt="NYAF"
              className="cyberpunk-logo"
            />
          </div>
          <div className="image-container-bg-mobile">
            <div
              className="container-v"
              style={{
                position: "relative",
                height: "100%",
                width: "100%",
                padding: "0px 10px",
              }}
            >
              <div
                className="text black-text"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  marginBottom: "5px",
                  textAlign: "start"
                }}
              >
                14 ДЕКАБРЯ \\ Уфа \\ ГКДЦ «ХИМИК»
              </div>
              <img
                className="mascot-background"
                src="MascotBG-mobile.png"
                alt="mascot"
                style={{ alignSelf: "center" }}
              />
              <ApplicationsCountdown
                targetDate={applicationDate}
                title={applicationsTitle.toUpperCase()}
              />
            </div>
           
          </div>
          {/* <div className="image-container-bg-mobile">
          <img className="image" src="Mascot2.png" alt="mascot" />
          <ApplicationsCountdown
            targetDate={applicationDate}
            title={applicationsTitle}
          />
          <img className="clouds-mobile" src="Clouds.png" alt="NYAF" />
        </div> */}

          {/* <div className="container-v center mobile-logo">
          <div>
            <img className="big-logo" src="NYAF_pink.svg" alt="NYAF" />
            <img className="sub-logo" src="23.svg" alt="23" />
          </div>
          <div className="text black-text">
            16 декабря • Уфа • ДК «Орджоникидзе»
          </div>

          {!fest && (
            <button
              className="text button buy-ticket-button wrap"
              onClick={() => {
                window.location.pathname = "/tickets";
              }}
            >
              КУПИТЬ БИЛЕТ
            </button>
          )}
          {fest && (
            <>
              <button
                className="text button buy-ticket-button wrap"
                style={{ marginBottom: "10px" }}
                onClick={() => {
                  window.location.pathname = "/schedule";
                }}
              >
                ПРОГРАММА ФЕСТИВАЛЯ
              </button>
              <div
                className="text black-text"
                style={{
                  minWidth: "290px",
                  whiteSpace: "nowrap",
                  fontSize: "13px",
                  textAlign: "center",
                }}
              >
                Программа фестиваля, зрительское <br /> голосование, карта ДК и{" "}
                <br /> полезная информация
              </div>
            </>
          )}
          {!fest && <FestivalCountdown targetDate={"16.12.2023"} />}
        </div> */}
        </div>
      </div>
    </>
  );
};

export default LogoSection;
