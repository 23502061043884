import React from "react";

import "./Tickets.css";
import TicketsWidget from "../components/TicketsWidget";
import OfflineShopsWidget from "../components/OfflineShopsWidget";

function Tickets() {
  return (
    <div className="contest longread">
      <div className="contest-section container-v center">
        <div className="content tickets-content">
          <div
            className="container-v center"
            // dangerouslySetInnerHTML={{ __html: ticketsData }}
          >
            <h1 className="header">БИЛЕТЫ</h1>
            {/* <h2 className="text" style={{ marginBottom: "30px" }}>
              Вы можете приобрести билеты на фестиваль в магазинах наших
              партнеров:{" "}
            </h2>
            <OfflineShopsWidget />
            <h2 className="text" style={{ marginTop: "30px" }}>
              или купить онлайн:
            </h2> */}
            <TicketsWidget />
            <div className="body-text" style={{ marginTop: "20px" }}>
              О старте продажи билетов мы сообщим отдельно. Следите за новостями
              в наших социальных сетях:{" "}
              <a
                className="more-text"
                href="vk.com/nyafest"
                target="_blank"
                rel="noreferrer"
              >
                VK
              </a>{" "}
              и
              <a
                className="more-text"
                href="https://t.me/nyafestival"
                target="_blank"
                rel="noreferrer"
              >
                Telegram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tickets;
