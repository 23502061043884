import React, { useEffect, useState } from "react";
import "./MenuDrawer.css";
import { Page, getPages } from "../services/pageService";
import { getUserPhotoLink } from "../utils/authUtils";
import { UserProfile } from "../types/UserProfile";

interface MenuDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  showLoginDrawer: () => void;
  isUserLoggedIn: boolean;
  onLogout: () => void;
  userProfile: UserProfile;
}

const MenuDrawer: React.FC<MenuDrawerProps> = ({
  isOpen,
  onClose,
  showLoginDrawer,
  isUserLoggedIn,
  onLogout,
  userProfile,
}) => {
  const drawerStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    right: isOpen ? "0" : "-90vw",
    width: "90vw",
    height: "100%",
    backgroundColor: "#fff",
    boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.2)",
    transition: "right 0.3s ease-in-out",
    zIndex: 1000,
    overflowY: "auto",
  };

  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: isOpen ? "block" : "none",
    zIndex: 999,
  };

  const [contestPages, setContestPages] = useState<Page[]>([]);

  useEffect(() => {
    async function fetchPages() {
      try {
        const pagesData = await getPages();
        setContestPages(
          pagesData
            .filter((p) => p.route.includes("contest"))
            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        );
      } catch (error) {
        // Handle error
        console.error("Error fetching pages:", error);
      }
    }

    fetchPages();
  }, []);

  const getSvg = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="16"
        viewBox="0 0 11 16"
        fill="none"
      >
        <path
          d="M2.25 14.5L8.75 8L2.25 1.5"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    );
  };

  const getSvgBack = () => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="16"
        viewBox="0 0 11 16"
        fill="none"
      >
        <path
          d="M8.75 1.5L2.25 8L8.75 14.5"
          stroke="black"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    );
  };

  const toggleMenuSection = (menuSectionName: string) => {
    const menuSections = document.querySelectorAll("div.menu-section");
    menuSections.forEach((m) => {
      if (m.id === menuSectionName) {
        m.setAttribute("style", "display:block");
      } else {
        m.setAttribute("style", "display:none");
      }
    });

    // const loginSection = document.getElementById("login-section");
    // if (menuSectionName === "main-section") {
    //   loginSection?.setAttribute("style", "display:flex");
    // } else {
    //   loginSection?.setAttribute("style", "display:none");
    // }
  };

  return (
    <div>
      <div className="container-v center" style={drawerStyle}>
        {isUserLoggedIn && (
          <div
            id="login-section"
            className="container-v center menu-login-section"
          >
            <div
              className="container center"
              style={{
                gap: "6px",
                margin: "25px 10px 0px 10px",
                width: "100%",
              }}
            >
              <img
                src={getUserPhotoLink() ?? "/Userpic.png"}
                alt="Userpic"
                className="userpic-small"
              />
              <div
                className="text link-text-small"
                style={{ wordWrap: "normal", fontSize: "23px" }}
              >
                {userProfile?.username ?? ""}
              </div>
            </div>
            <div
              className="container center"
              style={{
                width: "-webkit-fill-available",
                justifyContent: "center",
                gap: "20px",
                margin: "30px 15px 0px 15px",
              }}
            >
              {/* <a className="text link-text-small" href="/my-profile">
                Мой профиль
              </a>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="34"
                viewBox="0 0 2 34"
                fill="none"
              >
                <path d="M1 0V34" stroke="black" strokeWidth="2" />
              </svg> */}
              <a className="text link-text-small" href="/my-applications">
                Мои заявки
              </a>
            </div>
          </div>
        )}
        <div className="container mobile-menu-section">
          <div className="menu-section" id="main-section">
            <div className="container-v center">
              <div className="menu-item">
                <a href="/about" className="link">
                  <span className="menu-text">О нас</span>
                </a>
              </div>
              <div className="menu-item">
                <div
                  className="link"
                  onClick={() => toggleMenuSection("info-section")}
                >
                  <span className="menu-text">Информация</span>
                  <span className="menu-icon">{getSvg()}</span>
                </div>
              </div>
              <div className="menu-item">
                <div
                  className="link"
                  onClick={() => toggleMenuSection("contest-section")}
                >
                  <span className="menu-text">Конкурсы</span>
                  <span className="menu-icon">{getSvg()}</span>
                </div>
              </div>
              <div className="menu-item">
                <div
                  className="link"
                  onClick={() => toggleMenuSection("hall-section")}
                >
                  <span className="menu-text">Фойе</span>
                  <span className="menu-icon">{getSvg()}</span>
                </div>
              </div>
              <div className="menu-item">
                <a href="/partners" className="link">
                  Партнеры
                </a>
              </div>
            </div>
          </div>

          <div className="menu-section sub-section" id="info-section">
            <div className="container-v center">
              <div className="menu-item" style={{ marginBottom: "50px" }}>
                <div
                  className="link menu-link"
                  onClick={() => toggleMenuSection("main-section")}
                >
                  <span className="menu-icon" style={{ marginRight: "10px" }}>
                    {getSvgBack()}
                  </span>
                  <span className="menu-text">Информация</span>
                </div>
              </div>
              <div className="menu-item">
                <a href="/what-where-when" className="link">
                  Что? Где? Когда?
                </a>
              </div>
              <div className="menu-item">
                <a href="/tickets" className="link">
                  Билеты
                </a>
              </div>
              <div className="menu-item">
                <a href="/faq" className="link">
                  FAQ
                </a>
              </div>
              <div className="menu-item">
                <a href="/rules" className="link">
                  Правила фестиваля
                </a>
              </div>
              {/* <div className="menu-item">
                <a href="/schedule" className="link">
                  Программа фестиваля
                </a>
              </div> */}
            </div>
          </div>

          <div className="menu-section sub-section" id="hall-section">
            <div className="container-v center">
              <div className="menu-item" style={{ marginBottom: "50px" }}>
                <div
                  className="link"
                  onClick={() => toggleMenuSection("main-section")}
                >
                  <span className="menu-icon" style={{ marginRight: "10px" }}>
                    {getSvgBack()}
                  </span>
                  <span className="menu-text">Фойе</span>
                </div>
              </div>
              <div className="menu-item">
                <a href="/hall/fair" className="link">
                  Ярмарка
                </a>
              </div>
              <div className="menu-item">
                <a href="/hall/authors" className="link">
                  Аллея Авторов
                </a>
              </div>
              <div className="menu-item">
                <a href="/hall/accreditation" className="link">
                  Аккредитация
                </a>
              </div>
              {/* <div className="menu-item">
                <a href="/hall/applications" className="link">
                  Пришедшие заявки
                </a>
              </div> */}
            </div>
          </div>

          <div className="menu-section sub-section" id="contest-section">
            <div className="container-v center">
              <div className="menu-item" style={{ marginBottom: "50px" }}>
                <div
                  className="link"
                  onClick={() => toggleMenuSection("main-section")}
                >
                  <span className="menu-icon" style={{ marginRight: "10px" }}>
                    {getSvgBack()}
                  </span>
                  <span className="menu-text">Конкурсы</span>
                </div>
              </div>
              {contestPages.map((p) => (
                <div key={p.pageId} className="menu-item">
                  <a href={`/${p.route}`} className="link">
                    {p.title}
                  </a>
                </div>
              ))}
              {/* <div className="menu-item">
                <a href="/all-applications" className="link">
                  Пришедшие заявки
                </a>
              </div>
              <div className="menu-item">
                <a href="/photocosplay/vote" className="link">
                  Голосование Фотокосплей
                </a>
              </div> */}
            </div>
          </div>
        </div>
        <div className="mobile-menu-login">
          {!isUserLoggedIn ? (
            <button onClick={showLoginDrawer} className="login-button">
              Войти{" "}
              <img
                src="/Login.svg"
                alt="Войти"
                style={{ marginLeft: "14px" }}
              />
            </button>
          ) : (
            <button className="login-button" onClick={() => onLogout()}>
              Выйти
              <img
                src="/Logout.svg"
                alt="Выйти"
                style={{ marginLeft: "14px", width: "35px", height: "35px" }}
              />
            </button>
          )}
        </div>
      </div>
      <div style={overlayStyle} onClick={onClose}></div>
    </div>
  );
};

export default MenuDrawer;
