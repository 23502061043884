import React from "react";

import "./TicketsWidget.css";

function TicketsWidget() {
  const getTicketCard = (
    color: string,
    title: string,
    list: string[],
    prices: { price: number; subtitle?: string }[],
    button?: boolean,
    subtitle?: string
  ) => {
    return (
      <div className={`ticket-card ${color}`}>
        <div className="text ticket-title">{title}</div>
        <ul style={{ marginBottom: "0px" }}>
          {list.map((l, index) => {
            return (
              <li className="ticket-list" key={`list_${index}`}>
                {l}
              </li>
            );
          })}
        </ul>
        <div className="container ticket-prices">
          {prices.map((p, index) => {
            return (
              <div
                key={`price_${index}`}
                className="container-v"
                style={{
                  alignItems: "flex-start",
                  opacity: `${index > 0 ? "60%" : "100%"}`,
                }}
              >
                {p.subtitle && (
                  <div className="ticket-price-subtitle">{p.subtitle}</div>
                )}
                <div
                  className="ticket-title"
                  style={{ fontWeight: "bold" }}
                >{`${p.price} ₽`}</div>
              </div>
            );
          })}
        </div>
        {button && (
          <a
            className="tickets-buy-button"
            href="https://ufa.qtickets.events/89279-nyaf-2023"
            target="_blank"
            rel="noreferrer"
          >
            КУПИТЬ БИЛЕТ
          </a>
        )}
        {subtitle && <div className="text ticket-subtitle">{subtitle}</div>}
      </div>
    );
  };

  return (
    <div className="container center" style={{ gap: "25px", flexWrap: "wrap" }}>
      {getTicketCard(
        "pink",
        "БИЛЕТ ГОСТЯ",
        ["Проход на площадку проведения фестиваля"],
        [
          { price: 1000 },
        ],
        //true
      )}
      {getTicketCard(
        "yellow",
        "VIP-БИЛЕТ",
        [
          "Проход на площадку проведения фестиваля вне очереди",
          "Гарантированное место в зрительном зале",
          "Набор эксклюзивного мерча фестиваля NYAF 2024",
        ],
        [{ price: 3000 }],
        //true
      )}
      {getTicketCard(
        "blue",
        "БИЛЕТ УЧАСТНИКА",
        ["Предоставляется только участникам, прошедшим конкурсный отбор"],
        [{ price: 450 }],
        false,
        "Приобретается на входе в день фестиваля"
      )}
    </div>
  );
}

export default TicketsWidget;
