import React, { ReactNode, useEffect, useState } from "react";
import "./Apply.css";
import {
  Field,
  Nomination,
  PostApplication,
  SubNomination,
} from "../admin/types";
import ReactAudioPlayer from "react-audio-player";
import { useSearchParams } from "react-router-dom";
import { getNomination } from "../services/nominationsService";
import {
  getMyApplications,
  postApplication,
  putApplication,
} from "../services/applicationsService";
import { isAccessTokenValid } from "../utils/authUtils";
import { FILES_URL } from "../config";

type ApplicationData = {
  fieldId: string;
  value: string;
};

function Apply() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fieldsData, setFieldsData] = useState<Field[]>([]);
  const [applicationData, setApplicationData] = useState<ApplicationData[]>();
  const [nomination, setNomination] = useState<Nomination>();
  const [subNominations, setSubNominations] = useState<SubNomination[]>([]);
  const [mode, setMode] = useState<"create" | "update">("create");
  const [applicationState, setApplicationState] = useState<
    "success" | "failure" | undefined
  >(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const [fileUploadErrors, setFileUploadErrors] = useState<string[]>();

  const isDesktop = window.screen.width > 1450;

  useEffect(() => {
    async function fetchPage() {
      setIsLoading(true);
      const nominationId = searchParams.get("nominationId");
      if (nominationId) {
        try {
          var applicationData: ApplicationData[] | undefined;
          var application;
          const applicationId = searchParams.get("applicationId");
          if (applicationId) {
            setMode("update");
            const userApplications = await getMyApplications();
            const appl = userApplications.find(
              (a) => a.applicationId === applicationId
            );
            if (appl) {
              application = appl;
              applicationData = appl.applicationData;
              setApplicationData(appl.applicationData);
            }
          }

          const nom = await getNomination(nominationId);
          setNomination(nom);
          setSubNominations(nom.subNominations ?? []);
          const nomFields =
            nom.nominationFields
              ?.sort((a, b) => a.order - b.order)
              .map((f) => f.field) ?? [];

          if (applicationData) {
            nomFields.forEach((f) => {
              const value = applicationData?.find(
                (d) => d.fieldId === f.fieldId
              )?.value;
              if (value) {
                f.value = value;
              }
              if (f.code === "fio_group") {
                const values: string[] = value?.split(",") ?? [];
                if (values.length > 1) {
                  const existingFioFields = values
                    .slice(1)
                    .map((v, index) => getExtraFioField(f, v, index));

                  setFioFields(existingFioFields);
                }
              }
            });
          }
          if ((nom.subNominations ?? []).length > 1) {
            const subNominationField: Field = {
              order: 0,
              code: "subnomination",
              label: "Выберите номинацию",
              type: "select",
              required: true,
              category: "application",
              dependsOn: null,
              options: nom.subNominations?.map((sn) => {
                return { id: sn.subNominationId ?? "", value: sn.name };
              }),
              value: application
                ? application.subNomination.subNominationId
                : undefined,
            };

            setFieldsData([subNominationField, ...nomFields]);
          } else {
            setFieldsData(nomFields);
          }
        } catch (error) {
          // Handle error
          console.error("Error fetching fields:", error);
        }
      } else {
        window.location.href = "/";
      }
      setIsLoading(false);
    }

    if (isAccessTokenValid()) {
      fetchPage();
    }
    // eslint-disable-next-line
  }, [searchParams]);

  const getCategoryFields = (categoryName: string, categoryCode: string) => {
    var categoryFields = fieldsData.filter(
      (f) => f.category === categoryCode && !f.dependsOn
    );
    return (
      categoryFields.length !== 0 && (
        <div key={categoryCode} className="container-v" style={{ gap: "15px" }}>
          <h2>{categoryName}</h2>
          {categoryFields
            .sort((a, b) => a.order - b.order)
            .map((f) => {
              return getFieldGroup(f);
            })}
        </div>
      )
    );
  };

  const getFieldGroup = (field: Field): ReactNode => {
    const dependantField = fieldsData.find(
      (f) => f.dependsOn === field.fieldId
    );
    return (
      <div
        key={field.fieldId}
        className="container field-group-dependancy"
        style={{
          gap: "20px",
          width: `${
            field.type.includes("upload") ||
            field.type === "textarea" ||
            dependantField
              ? "100%"
              : isDesktop
              ? "50%"
              : "100%"
          }`,
        }}
      >
        {getField(field, dependantField ? "" : "field-solo")}
        {dependantField && getField(dependantField)}
      </div>
    );
  };

  const [fioFields, setFioFields] = useState<JSX.Element[]>([]);
  const [fioFieldsIndex, setFioFieldsIndex] = useState<number>(1);
  const getGroupFioField = (
    label: JSX.Element,
    initialField: Field,
    value?: string
  ) => {
    const values: string[] = value?.split(",") ?? [];

    return (
      <div id="fio_container_group" className="container-v center">
        <div className={`container-v field`}>
          {label}
          <input
            type="text"
            id={initialField.code + "0"}
            name={initialField.code + "0"}
            required={initialField.required}
            defaultValue={values.length > 0 ? values[0] : ""}
          />
        </div>
        {fioFields}
        <img
          onClick={() => onFioFieldAdd(initialField)}
          src="/plus.svg"
          alt="add"
          style={{ width: "44px", cursor: "pointer", marginTop: "15px" }}
        />
      </div>
    );
  };

  const onFioFieldAdd = (initialField: Field, value?: string) => {
    setFioFields((previousFields) => [
      ...previousFields,
      getExtraFioField(initialField, value),
    ]);
  };
  const removeFioField = (id: number) => {
    setFioFields((prevFioFields) =>
      prevFioFields.filter((f) => f.key !== `fiofield_${id}`)
    );
  };
  const getExtraFioField = (
    initialField: Field,
    value?: string,
    index?: number
  ) => {
    const extraField = (
      <div
        key={`fiofield_${index ?? fioFieldsIndex}`}
        className={`container field`}
        style={{ marginTop: "10px" }}
      >
        <input
          type="text"
          id={initialField.code + index ?? fioFieldsIndex}
          name={initialField.code + index ?? fioFieldsIndex}
          defaultValue={value ?? ""}
          style={{ width: "100%" }}
        />
        <img
          onClick={() => removeFioField(index ?? fioFieldsIndex)}
          src="/minus.svg"
          alt="add"
          style={{ width: "44px", cursor: "pointer", marginLeft: "15px" }}
        />
      </div>
    );
    setFioFieldsIndex((index ?? fioFieldsIndex) + 1);
    return extraField;
  };
  const getField = (field: Field, className = ""): ReactNode => {
    const label = (
      <label className="text field-label container-v" htmlFor={field.code}>
        <div className="container">
          <span>{field.label} </span>
          <span
            style={{
              display: field.required ? "flex" : "none",
              color: "#01BBF8",
              marginLeft: "5px",
            }}
          >
            *
          </span>
        </div>
        <span className="subtitle">{field.subtitle}</span>
      </label>
    );

    if (field.code === "fio_group") {
      return getGroupFioField(label, field, field.value);
    }

    switch (field.type) {
      case "text":
        return (
          <div className={`container-v field ${className}`}>
            {label}
            <input
              type="text"
              id={field.code}
              name={field.code}
              required={field.required}
              defaultValue={field.value}
            />
          </div>
        );

      case "textarea":
        return (
          <div className={`container-v field ${className}`}>
            {label}
            <textarea
              id={field.code}
              name={field.code}
              rows={9}
              required={field.required}
              defaultValue={field.value}
            />
          </div>
        );

      case "select":
        if (field.code === "defile-start") {
          field.options = [
            {
              id: "Из-за кулис",
              value: "Из-за кулис",
            },
            {
              id: "С точки",
              value: "С точки",
            },
          ];
        }

        if (field.code === "schedule-wish") {
          field.options = [
            {
              id: "Нет пожеланий",
              value: "Нет пожеланий",
            },
            {
              id: "1 Блок",
              value: "1 Блок",
            },
            {
              id: "2 Блок",
              value: "2 Блок",
            },
            {
              id: "3 Блок",
              value: "3 Блок",
            },
          ];
        }
        return (
          <div className={`container-v field ${className}`}>
            {label}
            <select
              id={field.code}
              name={field.code}
              required={field.required}
              defaultValue={field.value}
            >
              <option value="">Выберите...</option>
              {field.options?.map((op) => {
                return <option value={op.id}>{op.value}</option>;
              })}
            </select>
          </div>
        );

      case "upload-image":
        return (
          <div
            className={`container field ${className}`}
            style={{ justifyContent: "flex-start" }}
          >
            <label className="text field-label" htmlFor={field.code}>
              {field.label} <br />{" "}
              <span className="subtitle">{field.subtitle}</span>
              <div className="upload-button">
                {field.fileName ? `${field.fileName}` : "Загрузить с диска"}
              </div>
              <div className="subtitle field-error">{field.fileError}</div>
            </label>
            <input
              id={field.code}
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              onChange={(e) => onFileInputChange(e, field)}
              style={{ display: "none" }}
              name={field.code}
            />
            {getImagePreview(field)}
          </div>
        );

      case "upload-music":
        return (
          <div
            className={`container field ${className}`}
            style={{ justifyContent: "flex-start" }}
          >
            <label className="text field-label" htmlFor={field.code}>
              {field.label} <br />{" "}
              <span className="subtitle">{field.subtitle}</span>
              <div className="upload-button">
                {field.fileName ? `${field.fileName}` : "Загрузить с диска"}
              </div>
            </label>
            <input
              id={field.code}
              type="file"
              accept=".mp3"
              onChange={(e) => onFileInputChange(e, field)}
              style={{ display: "none" }}
              name={field.code}
            />
            {getMusicPreview(field)}
          </div>
        );
      default:
        break;
    }
  };

  const onFileInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: Field
  ) => {
    if (e.target.files && e.target.files[0]) {
      const updatedFields = [...fieldsData];
      const updatedField = updatedFields.find(
        (f) => f.fieldId === field.fieldId
      );
      if (validateImage(e.target)) {
        let reader = new FileReader();
        reader.onload = (ev) => {
          if (updatedField) {
            updatedField.fileError = "";
            updatedField.fileUri = ev.target?.result?.toString();
            updatedField.fileName = e.target.files
              ? e.target.files[0].name
              : "";
            setFieldsData(updatedFields);
          }
        };
        reader.readAsDataURL(e.target.files[0]);
      } else {
        if (updatedField) {
          updatedField.fileError =
            "Максимально допустимый размер файла - 10 МБ";
          setFieldsData(updatedFields);
        }
      }
    }
  };

  const validateImage = (field: EventTarget & HTMLInputElement) => {
    if (!field.files) {
      return false;
    } else {
      if (field.files[0].size > 10485760) {
        return false;
      }
    }
    return true;
  };

  const getImagePreview = (field: Field) => {
    if (field.fileUri) {
      return (
        <div
          className="container center img-container-thunmbnail"
          style={{ alignItems: "flex-end" }}
        >
          <img className="thumbnail" alt="thumbnail" src={field.fileUri}></img>
        </div>
      );
    }
    if (field.value) {
      return (
        <div className="container center" style={{ alignItems: "flex-end" }}>
          <img
            className="thumbnail"
            alt="thumbnail"
            src={`${FILES_URL}/${field.value}`}
          ></img>
        </div>
      );
    }
  };

  const getMusicPreview = (field: Field) => {
    if (field.fileUri) {
      return (
        <div className="container audio-preview">
          <ReactAudioPlayer src={field.fileUri} controls />
        </div>
      );
    }
    if (field.value) {
      return (
        <div className="container audio-preview">
          <ReactAudioPlayer src={`${FILES_URL}/${field.value}`} controls />
        </div>
      );
    }
  };

  const submitApplication = (e: React.FormEvent) => {
    e.preventDefault();
    var postApplication: PostApplication = { applicationData: [], files: [] };

    const updatedFields = [...fieldsData];

    updatedFields.forEach((field) => {
      if (field.code === "fio_group") {
        const fioValues: string[] = [];
        document
          .querySelectorAll('[id*="fio_group"]')
          .forEach((g) => fioValues.push((g as HTMLFormElement).value));
        field.value = fioValues.join(",");
        postApplication.applicationData?.push({
          fieldId: field.fieldId ?? "",
          value: fioValues.join(","),
        });
      } else {
        const fieldInput = (e.target as HTMLFormElement)[
          field.code
        ] as HTMLInputElement;

        if (field.type !== "upload-image" && field.type !== "upload-music") {
          field.value = fieldInput.value;
        }
        if (field.code !== "subnomination") {
          if (field.type !== "upload-image" && field.type !== "upload-music") {
            postApplication.applicationData?.push({
              fieldId: field.fieldId ?? "",
              value: fieldInput.value,
            });
          }
        } else {
          postApplication.subNominationId = fieldInput.value;
        }

        if (field.type === "upload-image" || field.type === "upload-music") {
          if (fieldInput.files && fieldInput.files[0]) {
            field.value = fieldInput.value;
            postApplication.applicationData?.push({
              fieldId: field.fieldId ?? "",
              value: fieldInput.value,
            });
            postApplication.files?.push({
              fieldId: field.fieldId ?? "",
              fieldLabel: field.label ?? "",
              file: fieldInput.files[0],
            });
          } else {
            postApplication.applicationData?.push({
              fieldId: field.fieldId ?? "",
              value: field.value ?? "",
            });
          }
        }
      }
    });

    if (postApplication.subNominationId === undefined) {
      postApplication.subNominationId = subNominations[0]?.subNominationId;
    }
    setFieldsData(updatedFields);
    apply(postApplication);
  };

  const apply = async (application: PostApplication) => {
    setIsLoading(true);
    if (mode === "create") {
      const response = await postApplication(application);
      setApplicationState(response.success ? "success" : "failure");
      if (response.errors) {
        setFileUploadErrors(response.errors);
      }
    }
    if (mode === "update") {
      application.applicationId = searchParams.get("applicationId") ?? "";
      const response = await putApplication(application);
      setApplicationState(response.success ? "success" : "failure");
      if (response.errors) {
        setFileUploadErrors(response.errors);
      }
    }
    setIsLoading(false);
  };

  const getForm = () => {
    return (
      <form className="form-apply" onSubmit={(e) => submitApplication(e)}>
        <div className="container-v" style={{ width: "100%", gap: "15px" }}>
          {getCategoryFields(
            `${
              nomination?.name === "Групповое косплей дефиле" ||
              nomination?.name === "Cover Dance"
                ? "Данные капитана команды"
                : "Ваши данные"
            }`,
            "personal"
          )}
          {getCategoryFields("Команда", "group")}
          {getCategoryFields("Информация о выступлении", "application")}
          {getCategoryFields("Материалы заявки", "application-media")}
          {getCategoryFields("Дополнительные материалы", "extra")}
        </div>
        <div className="container">
          <button type="submit" className="text button apply-button wrap">
            {`${mode === "create" ? "ПОДАТЬ ЗАЯВКУ" : "СОХРАНИТЬ"}`}
          </button>
          {mode === "update" && (
            <a
              className="text button apply-button wrap cancel-button"
              href="/my-applications"
              onClick={() => {
                setIsLoading(true);
              }}
            >
              ОТМЕНА
            </a>
          )}
        </div>
      </form>
    );
  };

  const getSuccessMessage = () => {
    return (
      <div className="container-v" style={{ alignItems: "flex-start" }}>
        <h2>
          Ваша заявка успешно {mode === "create" ? "подана" : "отредактирована"}
          !
        </h2>

        {fileUploadErrors && (
          <>
            <div className="text" style={{ color: "red", fontSize: "24px" }}>
              ВНИМАНИЕ!!
            </div>
            <div className="text">
              При подаче заявки произошла ошибка при загрузке следующих файлов:{" "}
              <span style={{ color: "red" }}>{fileUploadErrors.join(",")}</span>
            </div>
            <div className="text">
              Пожалуйста отредактируйте заявку и загрузите файл заново.
            </div>
          </>
        )}
        <a
          className="text button apply-button wrap"
          style={{ color: "#ffffff", marginTop: "30px" }}
          href="/my-applications"
          onClick={() => {
            setIsLoading(true);
          }}
        >
          МОИ ЗАЯВКИ
        </a>
      </div>
    );
  };

  const getFailureMessage = () => {
    return (
      <div className="container-v" style={{ alignItems: "flex-start" }}>
        <h2>Что-то пошло не так :(</h2>
        <button
          className="text button apply-button wrap"
          style={{ color: "#ffffff" }}
          onClick={() => {
            setApplicationState(undefined);
            setFileUploadErrors(undefined);
          }}
        >
          ПОПРОБОВАТЬ ЕЩЕ РАЗ
        </button>
        <p style={{ marginTop: "50px" }}>
          Если не получается подать заявку несколько раз подряд, обратитесь к
          организаторам!
        </p>
      </div>
    );
  };

  const getLogInMessage = () => {
    return (
      <div className="container-v" style={{ alignItems: "flex-start" }}>
        <h2>Для подачи заявки необходимо авторизоваться на сайте!</h2>
      </div>
    );
  };

  return (
    <div className="contest longread">
      {!isLoading ? (
        <div className="content-section container-v center">
          <div className="content longread-content">
            {isAccessTokenValid() ? (
              <>
                <h1>Форма заявки</h1>
                <h2>{nomination?.name}</h2>
                <hr />
                {applicationState === undefined && getForm()}
                {applicationState === "success" && getSuccessMessage()}
                {applicationState === "failure" && getFailureMessage()}
              </>
            ) : (
              getLogInMessage()
            )}
          </div>
        </div>
      ) : (
        <div style={{ height: "100vh" }}>
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Apply;
